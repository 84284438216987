// helpers
import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';
// actions
import changeEmailAddress from './actions/changeEmailAddress';
import changePhoneNumberAction from './actions/changePhoneNumber.action';
import changePassword from './actions/changePassword';
import forgot2fa from './actions/forgot2fa.action';
import forgotPassword from './actions/forgotPassword.action';
import initializeStore from './actions/initializeStore.action';
import login from './actions/login.action';
import logout from './actions/logout.action';
import userProfile from './actions/profileOverview.action';
import recoverTwoFactorAuthAction from './actions/recoverTwoFactorAuth.action';
import register from './actions/register.action';
import resetPasswordAction from './actions/resetPassword.action';
import resendVerificationEmail from './actions/resendVerificationEmail.action';
import setupAntiPhishingCode from './actions/setupAntiPhishingCode';
import setupPhoneNumberAction from './actions/setupPhoneNumber.action';
import updateUserProfile from './actions/updateUser.action';
import verifyEmail from './actions/verifyEmail.action';

// adapter
import adapterChangePassword from '@/services/profile/adapter/changePassword';
import adapterForgot from '@/services/auth/adapters/forgot.adapter';
import adapterLogin from '@/services/auth/adapters/login.adapter';
import adapterLogout from '@/services/auth/adapters/logout';
import adapterReset from '@/services/auth/adapters/reset.adapter';
import adapterChangeEmailAddress from '@/services/profile/adapter/changeEmailAddress';
import adapterUserOverview from '@/services/profile/adapter/getUserOverview';
import adapterRecoverTwoFactorAuth from '@/services/profile/adapter/recoverTwoFactorAuth';
import setupAntiPhishingCodeAdapter from '@/services/profile/adapter/setupAntiPhishingCode';
import {
  changePhoneNumberAdapter,
  setupPhoneNumberAdapter,
} from '@/services/profile/adapter/setupPhoneNumber';
import adapterRegister from '@/services/register/adapters/register.adapter';
import resendEmailVerificationAdapter from '@/services/auth/adapters/resendEmailVerification.adapter';

const state = {
  setupAntiPhishingCode: setState(setupAntiPhishingCodeAdapter),
  changePhoneNumber: setState(changePhoneNumberAdapter),
  changeEmailAddress: setState(adapterChangeEmailAddress),
  changePassword: setState(adapterChangePassword),
  forgotPassword: setState(adapterForgot),
  login: setState(adapterLogin),
  logout: setState(adapterLogout),
  resetPassword: setState(adapterReset),
  resendVerificationEmail: setState(resendEmailVerificationAdapter),
  recoverTwoFactorAuth: setState(adapterRecoverTwoFactorAuth),
  register: setState(adapterRegister),
  setupPhoneNumber: setState(setupPhoneNumberAdapter),
  token: null,
  userData: setState(adapterUserOverview),
  updateUser: setState(adapterUserOverview),
  verifyEmail: setState(resendEmailVerificationAdapter),
};

const actions = {
  setupAntiPhishingCode,
  changePhoneNumberAction,
  changeEmailAddress,
  changePassword,
  forgotPassword,
  forgot2fa,
  initializeStore,
  logout,
  login,
  register,
  resetPasswordAction,
  resendVerificationEmail,
  recoverTwoFactorAuthAction,
  setupPhoneNumberAction,
  updateUserProfile,
  userProfile,
  verifyEmail,
};

const getters = {
  isAuthenticated: (state) => !!state?.login?._data?.data?.email,
  userData: (state) => state.userData,
  updateUser: (state) => state.updateUser,
  getToken: (state) => state.token,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
  removeUserSession(state) {
    state.login._data.data = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
