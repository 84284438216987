const webserverUrl = process.env.VUE_APP_BASE_URL_SERVER;
const webserverPort = process.env.VUE_APP_BASE_URL_PORT;

export const baseUrl = `${webserverUrl}:${webserverPort}/api/v1`;

export const algorithmsUrls = {
  getAlgorithmList: () => `${baseUrl}/investor/algorithms`,
  getAlgorithmDetail: (id) => `${baseUrl}/investor/algorithms/${id}`,
  getAlgorithmTransactionList: (id) => `${baseUrl}/transactions/${id}`, //borrar
  getInvestmentDetails: (id) => `${baseUrl}/algorithms-investment-details/${id}`, //borrar
  algorithmReAllocation: () => `${baseUrl}/reallocation`,
  getAvailableBalance: (id) => `${baseUrl}/available-balance/${id}`,
  algorithmAllocation: () => `${baseUrl}/allocate`,
  closeAllocatedAlgorithm: (id) => `${baseUrl}/close-allocation/${id}`,
};

export const authUrls = {
  login: () => `${baseUrl}/login`,
  logout: () => `${baseUrl}/logout`,
  forgotPassword: () => `${baseUrl}/forgot-password`,
  // resetPassword: (token) => `${baseUrl}/access/password/reset/?token=${token}`,
  resetPassword: () => `${baseUrl}/reset-password`,
  resendVerificationEmail: () => `${baseUrl}/email/verification-notification`,
  forgot2fa: () => `${baseUrl}/forgot-2fa`,
  verifyEmail: (id, hash) => `${baseUrl}/email/verify/${id}/${hash}`,
};

export const dashboardUrls = {
  downloadReport: () => `${baseUrl}/download-report`,
  getAlgorithmList: () => `${baseUrl}/dashboard-algorithms`,
  getAlgorithmPerformance: () => `${baseUrl}/dashboard/algorithm-performance`,
  getOverviewChart: () => `${baseUrl}/dashboard/overview`,
  getNetAssetValue: () => `${baseUrl}/dashboard/nav`,
  getPerformance: () => `${baseUrl}/dashboard/performance`,
  getRiskLevel: () => `${baseUrl}/dashboard/risk`,
  getTransactionList: () => `${baseUrl}/investor/transactions`,
  getTransactionListCount: () => `${baseUrl}/investor/transactions`,
};

export const registerUrls = {
  register: () => `${baseUrl}/investor/register`,
};

export const subscriptionUrls = {
  calculateSubscription: () => `${baseUrl}/calculate-subscription`,
  confirmSubscription: () => `${baseUrl}/subscription`,
};

export const userProfileUrls = {
  getUserOverview: () => `${baseUrl}/investor/profile`,
  updateUserProfile: () => `${baseUrl}/investor/profile`,
  changePassword: () => `${baseUrl}/user/password`,
  setUpPhoneNumber: () => `${baseUrl}/user/phone-number`,
  changeEmailAddress: () => `${baseUrl}/user/change-email`,
  recoverTwoFactorAuth: () => `${baseUrl}/user/recover-2fa`,
  setupAntiPhishingCode: () => `${baseUrl}/user/anti-phishing-code`,
};

export const walletUrls = {
  getNetworkList: () => `${baseUrl}/investor/networks`,
  getPortfolio: () => `${baseUrl}/investor/portfolios`,
  getTotalBalance: () => `${baseUrl}/wallet/total-balance`,
  getWalletList: () => `${baseUrl}/investor/external-wallets`,
  addWalletAddress: () => `${baseUrl}/investor/external-wallets`,
  editWalletAddress: (id) => `${baseUrl}/investor/external-wallets/${id}`,
  getWalletSelectorList: () => `${baseUrl}/WalletSelectorList`,
  withdrawWallet: () => `${baseUrl}/withdraw`,
  deleteWallet: () => `${baseUrl}/investor/external-wallets`,
  deleteWalletById: (id) => `${baseUrl}/investor/external-wallets/${id}`,
  markAsDefault: (id) => `${baseUrl}/wallets/${id}/default`,
};

export const systemUrls = {
  getXsrf: () => `${baseUrl}/sanctum/csrf-cookie`,
};
