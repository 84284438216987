import { baseUrl } from '@/api/apiUrls';
import { useCustomToast } from '@/composables/toast/useCustomToast';
import { deleteAllCookies } from '@/helpers/cookies/deleteAllCookies';

import router from '@/router';
import store from '@/store';
import randomNumber from '@commons/helpers/generators/generateRandomNumber';
import axios from 'axios';

const captchaKey = process.env.VUE_APP_RECAPTCHA_KEY;

const addURLParams = (url, params) => {
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'number' || value) {
      url.searchParams.append(key, value);
    }
  });
};

const createFormData = (body) => {
  try {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  } catch (error) {
    console.log(error);
  }
};

const getCookieValue = (cookieName) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (const element of cookiesArray) {
    let cookie = element.trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};
const transformPaginate = (pagination) => {
  return {
    current_page: pagination.current_page,
    total_pages: pagination.total_pages,
    per_page: pagination.per_page,
    records: pagination.total,
  };
};
const transformResponse = (response, adapter = {}) => {
  if (!response)
    return {
      data: {},
    };
  let transformedResponse = {};
  if (response.code >= 200 && response.code < 400) {
    transformedResponse = {
      success: true,
    };
    if (response?.data && response.data.metadata) {
      transformedResponse.metadata = response.data.metadata;
    }
    if (response.data && !response.data.pagination && adapter) {
      if (Array.isArray(response.data)) {
        transformedResponse.data = [...adapter.data, ...response.data];
      } else if (typeof response.data === 'object') {
        transformedResponse.data = { ...adapter.data, ...response.data };
      }
    } else if (response.data && response.data.pagination) {
      transformedResponse.data = response.data.data;
      transformedResponse.paginate = transformPaginate(response.data.pagination);
    } else if (!response.data && response.message) {
      transformedResponse.data = {
        message: response.message,
      };
    }
  } else if (response.errors && Object.keys(response.errors).length > 0) {
    transformedResponse = {
      success: false,
      errors: response.errors,
    };
  } else if (response.message && response.code >= 400 && response.code < 500) {
    transformedResponse = {
      success: false,
      data: {
        message: response?.message,
      },
    };
  } else if (response.code >= 500) {
    transformedResponse = {
      success: false,
      data: {
        message: 'Server error. Please try again later.',
      },
    };
  }

  return transformedResponse;
};

export default async ({
  method,
  urlService,
  body,
  params = {},
  mockdata,
  adapter,
  debug,
  is_content_type_json = false,
}) => {
  if (debug) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          resolve({ response: { ...adapter, ...mockdata() } });
        },
        randomNumber(500, 1500),
      );
    });
  }

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    async (config) => {
      const recaptcha_token = await new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(captchaKey, { action: 'api_connect' }).then(resolve).catch(reject);
        });
      });
      config.headers['X-Recaptcha-Token'] = recaptcha_token;

      if (config?.method.toLocaleLowerCase() != 'get') {
        await axios.get(`${baseUrl}/sanctum/csrf-cookie`, {
          headers: {
            'X-Recaptcha-Token': recaptcha_token,
            Accept: 'application/json',
          },
          withCredentials: true,
        });

        const csrfToken = getCookieValue('XSRF-TOKEN');
        if (csrfToken) {
          config.headers['X-XSRF-TOKEN'] = csrfToken;
        }
      }

      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = config.contentType;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  const url = new URL(urlService);
  if (params) {
    addURLParams(url, params);
  }

  let data = is_content_type_json ? body : body ? createFormData(body) : null;
  const contentType = is_content_type_json ? 'application/json' : 'multipart/form-data';

  try {
    const response = await axiosInstance({
      method,
      url: url.href,
      data,
      contentType,
      withCredentials: true,
    });

    let responseData = transformResponse(response.data, adapter);
    return { response: responseData };
  } catch (err) {
    console.log(err);

    if (err.code == 'ERR_NETWORK') {
      return {
        response: {
          success: false,
          data: {
            message: err.message,
          },
        },
      };
    }

    const isAuthenticated = await store.getters['user/isAuthenticated'];

    if (err?.response?.status === 401 && isAuthenticated) {
      deleteAllCookies();
      store.commit('user/removeUserSession');
      router.push({ name: 'login' });
      useCustomToast({
        text: 'Session expired, please login again',
        type: 'error',
      });
    }

    const responseError = transformResponse(err.response?.data, adapter);
    return { response: responseError };
  }
};
