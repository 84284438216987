import { subscriptionUrls } from '@/api/apiUrls';
import http from '@/helpers/http';
import { httpMethods } from '../../config/constants';

// Adapters
import adapterCalculateSubscription from './adapters/calculateSubscription';
import adapterConfirmSubscription from './adapters/confirmSubscription';

// Mockdata
import mockCalculateSubscription from './mocks/calculateSubscription';
import { mockSubscription } from './mocks/confirmSubscription';

const calculateSubscriptionService = async () => {
  return await http({
    method: httpMethods.post,
    urlService: subscriptionUrls.calculateSubscription(),
    mockdata: mockCalculateSubscription,
    adapter: adapterCalculateSubscription,
    debug: true,
  });
};

const confirmSubscriptionService = async () => {
  return await http({
    method: httpMethods.post,
    urlService: subscriptionUrls.confirmSubscription(),
    mockdata: mockSubscription,
    adapter: adapterConfirmSubscription,
    debug: true,
  });
};

export { calculateSubscriptionService, confirmSubscriptionService };
