import generateLoadingMutations from '@commons/helpers/store/generateLoadingMutations';
import setData from '@commons/helpers/store/setData';
import setState from '@commons/helpers/store/setState';

import addWalletAddress from './actions/addWalletAddress';
import deleteWallet from './actions/deleteWallet';
import deleteWalletById from './actions/deleteWalletById';
import editWalletAddress from './actions/editWalletAddress';
import getNetworkList from './actions/getNetworkList';
import getPortfolio from './actions/getPortfolio';
import getTotalBalance from './actions/getTotalBalance';
import getWalletList from './actions/getWalletList';
import getWalletSelectorList from './actions/getWalletSelectorList';
import markAsDefault from './actions/markAsDefault';
import withdrawWallet from './actions/withdrawWallet';

import adapterAddWalletAddress from '@/services/wallet/adapters/addWalletAddress';
import adapterDeleteWallet from '@/services/wallet/adapters/deleteWallet';
import adapterGetNetworkList from '@/services/wallet/adapters/getNetworkList';
import adapterGetPortfolio from '@/services/wallet/adapters/getPortfolio';
import adapterGetTotalBalance from '@/services/wallet/adapters/getTotalBalance';
import adapterGetWalletSelectorList from '@/services/wallet/adapters/getWalletSelectorList';
import adapterMarkAsDefault from '@/services/wallet/adapters/markAsDefault';
import adapterWalletList from '@/services/wallet/adapters/getWalletList';
import adapterWithdrawWallet from '@/services/wallet/adapters/withdrawWallet';

const state = {
  addWalletAddress: setState(adapterAddWalletAddress),
  deleteWallet: setState(adapterDeleteWallet),
  deleteWalletById: setState(adapterDeleteWallet),
  editWalletAddress: setState(adapterAddWalletAddress),
  markAsDefault: setState(adapterMarkAsDefault),
  networkList: setState(adapterGetNetworkList),
  portfolio: setState(adapterGetPortfolio),
  totalBalance: setState(adapterGetTotalBalance),
  walletList: setState(adapterWalletList),
  walletSelectorList: setState(adapterGetWalletSelectorList),
  withdraw: setState(adapterWithdrawWallet),
};

const actions = {
  addWalletAddress,
  deleteWallet,
  deleteWalletById,
  editWalletAddress,
  getNetworkList,
  getPortfolio,
  getTotalBalance,
  getWalletList,
  getWalletSelectorList,
  markAsDefault,
  withdrawWallet,
};

const mutations = {
  ...generateLoadingMutations(),
  setData,
};

const getters = {
  portfolio: (state) => state.portfolio,
  totalBalance: (state) => state.totalBalance,
  getNetworkList: (state) => state.networkList,
  getWalletList: (state) => state.walletList,
  walletSelectorList: (state) => state.walletSelectorList,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
